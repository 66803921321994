<template>
  <task-page
  ></task-page>
</template>

<script>
import TaskPage from '@/views/ShoppingMail/Task/index.vue';
export default {
  components: {
    TaskPage,
  }
}
</script>
