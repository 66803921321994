var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "views_shopping_mail_task_home" }, [
    _vm.new_list && _vm.new_list.length
      ? _c(
          "div",
          { ref: "task_list", staticClass: "section" },
          [
            _c("title-module", {
              staticClass: "section_title_group mb-1",
              attrs: { title: "最新上架", show_more: false },
            }),
            _c(
              "div",
              {
                staticClass:
                  "list_group task_list_group d-flex align-start flex-wrap",
              },
              [
                _vm._l(_vm.new_list, function (item, index) {
                  return [
                    index < _vm.task_line_number
                      ? _c("task-module", {
                          key: index,
                          staticClass: "mr-16 task_module mb-16",
                          attrs: { ptask: item },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewTask(item)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.new_list && _vm.new_list.length
      ? _c(
          "div",
          { staticClass: "section" },
          [
            _c("title-module", {
              staticClass: "section_title_group mb-1",
              attrs: { title: "本周最热", show_more: false },
            }),
            _c(
              "div",
              {
                staticClass:
                  "list_group task_list_group d-flex align-start flex-wrap",
              },
              [
                _vm._l(_vm.hot_list, function (item, index) {
                  return [
                    index < _vm.task_line_number
                      ? _c("task-module", {
                          key: index,
                          staticClass: "mr-16 task_module mb-16",
                          attrs: { ptask: item },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewTask(item)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.all_list && _vm.all_list.length
      ? _c(
          "div",
          { staticClass: "section" },
          [
            _c("title-module", {
              staticClass: "section_title_group mb-1",
              attrs: { title: "全部" },
              on: {
                handleMore: function ($event) {
                  return _vm.clickMore({
                    name: "全部任务模板",
                    only: true,
                    type: "task",
                  })
                },
              },
            }),
            _c(
              "div",
              {
                staticClass:
                  "list_group all_task_list_group d-flex align-start flex-wrap",
              },
              [
                _vm._l(_vm.all_list, function (item, index) {
                  return [
                    index < _vm.task_line_number
                      ? _c("minor-unit", {
                          key: index,
                          staticClass: "task_module mb-20 cp",
                          attrs: { ptask: item },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewTask(item)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "nomore" }, [_vm._v("————  已经到底啦  ————")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }