var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_modules_task_unit_minor cp" },
    [
      _c("div", { staticClass: "unit_minor d-flex align-stretch" }, [
        _c("img", {
          staticClass: "avatar mr-12 flex-shrink-0",
          attrs: {
            src: `${_vm.$avatar_url}${_vm.create_user_thumbnail}@!small200`,
            alt: "",
          },
        }),
        _c(
          "div",
          {
            staticClass:
              "info_group d-flex flex-column justify-space-between align-start",
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("div", { staticClass: "data_group d-flex align-center" }, [
              _vm.sale
                ? _c(
                    "div",
                    { staticClass: "item_group d-flex align-center mr-10" },
                    [
                      _c("img", {
                        staticClass: "icon mr-4",
                        attrs: {
                          src: require("@/assets/images/shopping_mail/sale_icon.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.sale)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.readamount
                ? _c("div", { staticClass: "item_group d-flex align-center" }, [
                    _c("img", {
                      staticClass: "icon mr-4",
                      attrs: {
                        src: require("@/assets/images/shopping_mail/view_icon.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.readamount)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _c("hover-module", {
        staticClass: "hover_module",
        attrs: { pselected: _vm.pselected, ptask: _vm.ptask },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }