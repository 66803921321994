<template>
  <div
    class="views_shopping_mail_task_home"
  >
    <div class="section"
      v-if="new_list && new_list.length"
      ref="task_list"
    >
      <title-module
        class="section_title_group mb-1"
        title="最新上架"
        :show_more="false"
      ></title-module>
      <div class="list_group task_list_group d-flex align-start flex-wrap">
        <template
          v-for="(item,index) in new_list"
        >
          <task-module
            v-if="index<task_line_number"
            class="mr-16 task_module mb-16"
            :key="index"
            :ptask="item"
            @click.stop.native="viewTask(item)"
          ></task-module>
        </template>
      </div>
    </div>
    <div class="section"
      v-if="new_list && new_list.length"
    >
      <title-module
        class="section_title_group mb-1"
        title="本周最热"
        :show_more="false"
      ></title-module>
      <div class="list_group task_list_group d-flex align-start flex-wrap">
        <template
          v-for="(item,index) in hot_list"
        >
          <task-module
            v-if="index<task_line_number"
            class="mr-16 task_module mb-16"
            :key="index"
            :ptask="item"
            @click.stop.native="viewTask(item)"
          ></task-module>
        </template>
      </div>
    </div>
    <div class="section"
      v-if="all_list && all_list.length"
    >
      <title-module
        class="section_title_group mb-1"
        title="全部"
        @handleMore="clickMore({name: '全部任务模板', only: true, type: 'task'})"
      ></title-module>
      <div class="list_group all_task_list_group d-flex align-start flex-wrap">
        <template
          v-for="(item,index) in all_list"
        >
          <minor-unit
            v-if="index<task_line_number"
            class="task_module mb-20 cp"
            :key="index"
            :ptask="item"
            @click.stop.native="viewTask(item)"
          ></minor-unit>
        </template>
      </div>
    </div>
    <div class="nomore">————  已经到底啦  ————</div>
  </div>
</template>

<script>
import TitleModule from '@/views/ShoppingMail/Modules/Common/Title.vue';
import TaskModule from '@/views/ShoppingMail/Modules/Task/Unit/Major.vue';
import MinorUnit from '@/views/ShoppingMail/Modules/Task/Unit/Minor.vue';
import {PRotation, PTask} from '@/types';
export default {
  components: {
    TitleModule,
    TaskModule,
    MinorUnit,
  },
  data(){
    return {
      new_list: [],
      hot_list: [],
      all_list: [],
      task_line_number: 5, // 任务单行数据
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    async init(){
      await this.getData();
      this.$nextTick(function(){
        this.getTaskLineNumber();
      })
    },
    async getData(){
       const res = await this.$global.doPost({
        url: '/projectShop/taskHome',
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {rotations,tasksall,tasksshot,tasksnew} = contents;
        const new_list = this.$types.transformObjectForList({
          list: tasksnew,
          obj : PTask,
        })
        const hot_list = this.$types.transformObjectForList({
          list: tasksshot,
          obj : PTask,
        })
        const all_list = this.$types.transformObjectForList({
          list: tasksall,
          obj : PTask,
        })
        this.$set(this, 'new_list', new_list);
        this.$set(this, 'hot_list', hot_list);
        this.$set(this, 'all_list', all_list);
      }
      return res;
    },
    clickPackage(data){
      // 追加 package 及id
      this.$emit('handlePushNavigation', {type:'teaching_package', package_data: data.data})
    },
    /**
     * 教学包预览任务
     */
    viewTask(item){
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true});
    },
    clickMore(params){
      const {name, only, type} = params;
      this.$api.shopping_mail.pushNav({type: 'search', search_data:{
        name,
        only,
        type,
      }})
    },
    getTaskLineNumber(){
      const unit_width                     = 200 + 16;
      const task_list                      = this.$refs.task_list;
      const {clientWidth: task_list_width} = task_list;
      const num = Math.floor(task_list_width/unit_width);
      this.task_line_number = num;
    }
  },
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_task_home{
  width: 100%;
  padding-bottom: 100px;
  background-color: #fff;
  min-height: 100%;
  .carousel_wrapper{
    padding: {
      top: 20px;
      bottom: 30px;
    }
    margin: 0 auto;
  }
  .section{
    padding: 0 40px;
  }
  .section_title_group{
    padding-top: 30px;
  }
  .list_group{
    padding: 15px 0 15px;
  }
  .teaching_package{
    width: 18.9%;
    margin-right: 1.32%;
    &:nth-of-type(5n){
      margin-right: 0;
    }
  }
  .unit_mini{
    width: 32%;
  }
  .nomore{
    width: 100%;
    font-size: 16px;
    color: #D5D9DB;
    line-height: 21px;
    text-align: center;
    padding: 50px 0;
  }
  .all_task_list_group{
    .task_module{
      width: 32%;
    }
  }
}
</style>
