var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views_shopping_mail_task_index" },
    [
      _vm.home
        ? _c("home", { on: { handlePushNavigation: _vm.handlePushNavigation } })
        : _vm._e(),
      !_vm.home
        ? _c("div", { staticClass: "page d-flex flex-column" }, [
            _c(
              "div",
              { staticClass: "sticky_group bbox flex-shrink-0" },
              [
                _c("sub-navigation", {
                  staticClass: "sub_navigation py-10",
                  attrs: { navigation: _vm.navigation },
                  on: { handleChangeNavigation: _vm.handleChangeNavigation },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "main_group bbox" },
              [
                _vm.page_type && _vm.page_type === "task"
                  ? _c("details-module", {
                      attrs: { task_id: _vm.page_id, payment: _vm.payment },
                      on: { handlePushNavigation: _vm.handlePushNavigation },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }