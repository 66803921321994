import { render, staticRenderFns } from "./Minor.vue?vue&type=template&id=05216454&scoped=true"
import script from "./Minor.vue?vue&type=script&lang=js"
export * from "./Minor.vue?vue&type=script&lang=js"
import style0 from "./Minor.vue?vue&type=style&index=0&id=05216454&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05216454",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05216454')) {
      api.createRecord('05216454', component.options)
    } else {
      api.reload('05216454', component.options)
    }
    module.hot.accept("./Minor.vue?vue&type=template&id=05216454&scoped=true", function () {
      api.rerender('05216454', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShoppingMail/Modules/Task/Unit/Minor.vue"
export default component.exports