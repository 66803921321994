<template>
  <div
    class="views_shopping_mail_modules_task_unit_minor cp"
  >
    <div class="unit_minor d-flex align-stretch">
      <img
        :src="`${$avatar_url}${create_user_thumbnail}@!small200`"
        alt="" class="avatar mr-12 flex-shrink-0">
      <div class="info_group d-flex flex-column justify-space-between align-start">
        <div class="title">{{title}}</div>
        <div class="data_group d-flex align-center"
        >
          <div class="item_group d-flex align-center mr-10"
            v-if="sale"
          >
            <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
            <div class="text">{{sale}}</div>
          </div>
          <div class="item_group d-flex align-center"
            v-if="readamount"
          >
            <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
            <div class="text">{{readamount}}</div>
          </div>
        </div>
      </div>
    </div>
    <hover-module
      class="hover_module"
      :pselected="pselected"
      :ptask="ptask"
    ></hover-module>
  </div>
</template>

<script>
import HoverModule from './Hover.vue';
export default {
  components: {
    HoverModule,
  },
  props: {
    pselected: {
      type: Object,
      default: undefined,
    },
    ptask: {
      type: Object,
      default: undefined,
    }
  },
  computed: {
    title(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.title;
      }
      if(ptask){
        return ptask.name;
      }
      return '';
    },
    content(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.content;
      }
      if(ptask){
        return '';
      }
      return '';
    },
    create_time(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.createTime;
      }
      if(ptask){
        return ptask.createTime;
      }
      return '';
    },
    free(){
      const {pselected,ptask} = this;
      if(pselected){
        return false;
      }
      if(ptask){
        return ptask.price === 0;
      }
      return false;
    },
    create_user_thumbnail(){

      const {pselected,ptask} = this;
      if(pselected){
        return false;
      }
      if(ptask){
        return ptask.createuserthumbnail;
      }
      return false;
    },
    sale(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.sale;
      }
      if(ptask){
        return ptask.sale;
      }
      return undefined;
    },
    readamount(){
      const {pselected,ptask} = this;
      if(pselected){
        return pselected.task.readamount;
      }
      if(ptask){
        return ptask.readamount;
      }
      return undefined;
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_task_unit_minor{
  position: relative;
  .unit_minor{
    position: relative;
    z-index: 2;
    .avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: block;
    }
    .info_group{
      flex: 1;
      .title{
        font-size: 14px;
        color: #333333;
        line-height: 19px;
      }
      .data_group{
        font-size: 12px;
        color: #666666;
        line-height: 16px;
      }
    }
  }
  .hover_module{
    @include transition(0.1s);
    // transition-delay: 0.3s;
    opacity: 0;
    top:0;
    left: 0;
    transform-origin: top center;
    right: 0;
    position: absolute;
    z-index: 3;
    transform: scaleY(0);
  }
  &:hover{
    .hover_module{
      opacity: 1;
      transform: scaleY(1);
      top: -20px;
      left: -40px;
      right: -40px;
    }
  }
}
</style>
